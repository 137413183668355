@import "mixins";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,700;1,400&display=swap");
* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  body {
    font-family: "Roboto Mono", monospace;
    background-color: #f0ece8;
    color: rgb(54, 53, 53);
    ::-moz-selection {
      background: rgb(54, 53, 53);
      color: #ffffff;
    }

    ::selection {
      background: rgb(54, 53, 53);
      color: #ffffff;
    }

    #navDiv {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: 2;
      @include phone {
        top: 10px;
        right: 10px;
      }
      #navButton {
        padding: 15px;
        border-radius: 50%;
        background-color: transparent;
        border: none;
        @include phone {
          padding: 10px;
        }
        i {
          font-size: 30px;
          color: rgb(255, 255, 255);
          @include phone {
            font-size: 20px;
          }
        }
      }
    }
    #sidebar {
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 60px;
      z-index: 1;
      width: 250px;
      @include phone {
        width: 180px;
      }
      a {
        padding: 8px;
        text-decoration: none;
        font-size: 25px;
        color: #e4e0e0;
        display: block;
        transition: 0.3s;
      }
      a:hover {
        color: #ffffff;
      }
    }
    #header {
      color: #000000;
      background-image: url("./../assets/5432755.jpg");
      background-size: cover;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      #nameTitle {
        font-family: inherit;
        font-size: 400%;
        font-weight: 500;
        @include phone {
          font-size: 150%;
        }
      }
      h2 {
        font-weight: 500;
        padding: 20px 0;
        @include phone {
          font-size: 100%;
        }
      }
      button {
        margin: 10px;
        font-size: 100%;
        font-family: inherit;
        color: rgb(0, 0, 0);
        padding: 20px;
        background-color: transparent;
        border: 2px solid rgb(0, 0, 0);
        border-radius: 30px;
        transition: 0.3s linear;
        @include phone {
          font-size: 80%;
        }
      }
      button:hover {
        color: #fff;
        background-color: rgb(0, 0, 0);
      }
    }
    .title-main {
      padding: 20px 0px;
      text-align: center;
      font-size: 170%;
      margin: 0;
      width: 100%;
      color: rgb(29, 29, 29);
      @include phone {
        font-size: 150%;
      }
    }

    #about-me-section {
      min-height: 95vh;
      #who-am-i {
        background-color: #4787ff;
      }
      #aboutme-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include tablet {
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }
        @include phone {
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }
        #aboutme-box {
          width: 50%;
          margin: 50px 0;
          padding: 50px;
          background-color: #282c34;
          border-radius: 20px;
          box-shadow: 10px 10px 20px #282c3479;
          @include phone {
            font-size: 80%;
            padding: 30px;
            width: auto;
            margin: 50px 10px;
          }
          #about-me-css-text {
            .classtext {
              color: #d19a66;
            }
            .normaltext {
              color: #abb2bf;
            }
            .stringtext {
              color: #98c379;
            }
            .idtext {
              color: #61afef;
            }
            .elementtext {
              color: #e06c75;
            }
            a {
              color: inherit;
              text-decoration: none;
            }
          }
        }
        #text-box {
          width: 40%;
          padding: 50px 40px;
          font-size: 120%;
          line-height: 30px;
          @include tablet {
            font-size: 16px;
            width: 80%;
          }
          @include phone {
            font-size: 14px;
            width: 90%;
            padding: 0;
          }
          p {
            a {
              color: inherit;
            }
            @include phone {
              padding: 10px;
            }
          }
        }
        img {
          width: 20%;
          padding: 20px;
          @include phone {
            width: 50%;
          }
        }
      }
    }
    #skills-section {
      #skills {
        background-color: #e0aade;
      }
      .skills-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        #skills-box {
          margin: 30px 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          border-radius: 10px;
          width: 90%;
          font-size: 150%;
          @include phone {
            flex-direction: column;
            font-size: 100%;
          }

          #skills-info {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            border: 2px solid #282c34;
            padding: 5px;
            padding-bottom: 10px;
            margin: 10px;
            border-radius: 10px;
            @include phone {
              width: 90%;
              margin: 5px;
            }
            h2 {
              margin: 20px 0px;
              font-size: 120%;
            }
            .skill-item {
              padding: 10px 0px;
              width: 70%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              @include phone {
                width: 90%;
                font-size: 90%;
              }
            }
          }
        }

        .skills-container {
          padding: 50px;
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          width: 70%;
          i {
            padding: 30px;
            font-size: 800%;
            transition: 0.3s linear;
            // text-shadow: 2px 2px 8px #a8a39e;
            @include phone {
              font-size: 500%;
              padding: 15px;
            }
          }
          i:hover {
            text-shadow: 1px 1px 1px #6e6b67;
          }
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
    #portfolio-section {
      #portfolio {
        background-color: #5fcc8d;
      }
      #portfoliowrapper {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        .projektcontainer {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          height: 700px;
          width: 40%;
          padding: 20px;
          @include tablet {
            width: 100%;
            padding: 20px 0px;
          }
          @include phone {
            width: 100%;
            padding: 20px 0px;
          }
          .slick-slider {
            width: 100%;
            @include phone {
              width: 90%;
            }

            img {
              width: 100%;
              @include phone {
                width: 100%;
              }
            }
          }

          .projektlink {
            color: inherit;
          }
          .projekttitle {
            font-size: 20px;
            font-weight: 500;
          }
          .projektdescription {
            @include phone {
              width: 90%;
            }
          }
        }
      }
    }

    #contact-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      #contact {
        background-color: #ffa723;
      }
      .contact-wrapper {
        padding: 50px;
        display: flex;
        flex-direction: row;

        @include phone {
          flex-direction: column;
          padding: 0px;
          align-items: center;
          justify-content: center;
        }
        .info-container {
          width: 50%;
          justify-content: space-evenly;
          align-items: center;
          display: flex;
          flex-direction: column;
          @include phone {
            width: 80%;
          }
          .contactinfo {
            padding: 20px 0;

            p {
              padding: 10px;
              font-size: 120%;
              @include tablet {
                font-size: 16px;
              }
              @include phone {
                font-size: 14px;
              }
            }
            a {
              color: inherit;
            }
          }
          #socials {
            padding: 20px 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            a {
              font-size: 40px;
              padding: 20px;
              color: black;
              text-decoration: none;
              @include phone {
                font-size: 30px;
              }
            }
          }
        }
        .goodbye-container {
          width: 50%;
          justify-content: space-evenly;
          align-items: center;
          display: flex;
          flex-direction: column;
          @include phone {
            width: 80%;
          }
          img {
            width: 60%;
            padding: 10px;
            @include phone {
              width: 85%;
            }
          }
          p {
            padding: 20px;
          }
        }
      }
    }
  }
}
